import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

import { Media } from "../../utils/Media"

//Gatsby imports
import { graphql, useStaticQuery } from "gatsby"

//Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import Text from "./Text"
import Link from "./Link"
import Title from "./Title"
import MapWrapper from "./MapWrapper"
import SeznamMap from "./SeznamMap"
import Image from "./Image"
import MapOverlay from "./MapOverlay"

const Contact = () => {
  const intl = useIntl()
  let userLatestTap = 0

  const removeOverlay = ({ target }) => {
    target.remove()
  }

  const detectDoubleTap = e => {
    let now = new Date().getTime()
    let timeBetweenTwoLastTaps = now - userLatestTap
    if (timeBetweenTwoLastTaps < 500 && timeBetweenTwoLastTaps > 0) {
      // Was double tap
      removeOverlay(e)
    }
    userLatestTap = new Date().getTime()
  }

  const data = useStaticQuery(graphql`
    query ShopImageAndBackgroundQuery {
      image: file(relativePath: { eq: "prodejna.jpeg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: "pages.contacts.title" })}</Title>
      <Container>
        <div>
          <Media lessThan="desktop">
            <Text marginBottom="56px">
              Scutica, s. r. o.<br></br>
              <br></br>
              Boleslavská 13
              <br></br>
              <br></br>
              130 00 Praha 3 - Vinohrady<br></br>
              <br></br>
              Česká republika
            </Text>
            {/* <Text marginBottom="56px">
              Scutica, s. r. o.<br></br>
              <br></br>
              Husitská 74/39<br></br>
              <br></br>
              130 00 Praha 3 - Žižkov<br></br>
              <br></br>
              Česká republika
            </Text>

            <Text>
              {intl.formatMessage({ id: "pages.contacts.opening_hours_title" })}
              <br></br>
              <br></br>
              <span style={{ display: "inline-block", marginRight: "32px" }}>
                {intl.formatMessage({
                  id: "pages.contacts.opening_hours_days_1",
                })}
                :
              </span>
              11:00 - 19:00<br></br>
              <br></br>
              <span style={{ display: "inline-block", marginRight: "41px" }}>
                {intl.formatMessage({
                  id: "pages.contacts.opening_hours_days_2",
                })}
                :
              </span>
              {intl.formatMessage({
                id: "pages.contacts.closed",
              })}
              <br></br>
              <br></br>
            </Text> */}
          </Media>

          <Media greaterThanOrEqual="desktop">
            {/* <Text paddingLeft="62px" marginBottom="56px">
              <span style={{ fontSize: "30px" }}>Scutica, s. r. o.</span>
              <br></br>
              <br></br>
              Husitská 74/39<br></br>
              <br></br>
              130 00 Praha 3 - Žižkov<br></br>
              <br></br>
              Česká republika
            </Text> */}

            {/* <Text paddingLeft="62px">
              {intl.formatMessage({
                id: "pages.contacts.opening_hours_title",
              })}
              <br></br>
              <br></br>
              <span style={{ display: "inline-block", marginRight: "32px" }}>
                {intl.formatMessage({
                  id: "pages.contacts.opening_hours_days_1",
                })}
                :
              </span>
              11:00 - 19:00<br></br>
              <br></br>
              <span style={{ display: "inline-block", marginRight: "41px" }}>
                {intl.formatMessage({
                  id: "pages.contacts.opening_hours_days_2",
                })}
                :
              </span>
              {intl.formatMessage({
                id: "pages.contacts.closed",
              })}
              <br></br>
              <br></br>
            </Text> */}
            <Text paddingLeft="62px" marginBottom="56px">
              <span style={{ fontSize: "30px" }}>Scutica, s. r. o.</span>
              <br></br>
              <br></br>
              Boleslavská 13<br></br>
              <br></br>
              130 00 Praha 3 - Vinohrady<br></br>
              <br></br>
              Česká republika
            </Text>
          </Media>
        </div>
        <Media lessThan="desktop">
          <Text>
            {intl.formatMessage({
              id: "pages.contacts.phone",
            })}
            :<br></br>
            <Link href="tel:+420607476955" title="+420 601 532 867">
              +420 607 476 955
            </Link>
            <br></br> <br></br>
            {intl.formatMessage({
              id: "pages.contacts.email_orders",
            })}
            :<br></br>
            <Link
              href="mailto:objednavky@scutica.cz"
              title="objednavky@scutica.cz"
            >
              objednavky@scutica.cz
            </Link>
            <br></br> <br></br>
            {intl.formatMessage({
              id: "pages.contacts.email_production",
            })}
            :<br></br>
            <Link href="mailto:info@scutica.cz" title="info@scutica.cz">
              info@scutica.cz
            </Link>
          </Text>
        </Media>
        <Media greaterThanOrEqual="desktop">
          <Text paddingLeft="62px">
            {intl.formatMessage({
              id: "pages.contacts.phone",
            })}
            :<br></br>
            <Link href="tel:+420607476955" title="+420 601 532 867">
              +420 607 476 955
            </Link>
            <br></br> <br></br>
            {intl.formatMessage({
              id: "pages.contacts.email_orders",
            })}
            :<br></br>
            <Link
              href="mailto:objednavky@scutica.cz"
              title="objednavky@scutica.cz"
            >
              objednavky@scutica.cz
            </Link>
            <br></br> <br></br>
            {intl.formatMessage({
              id: "pages.contacts.email_production",
            })}
            :<br></br>
            <Link href="mailto:info@scutica.cz" title="info@scutica.cz">
              info@scutica.cz
            </Link>
          </Text>
        </Media>
        {/* <Image
          image={data.image.childImageSharp.gatsbyImageData}
          alt="Prodejna"
        /> */}
      </Container>
      <Title>
        {intl.formatMessage({
          id: "pages.contacts.where_to_find_us_title",
        })}
      </Title>
      <Text align="center">
        {intl.formatMessage({
          id: "pages.contacts.where_to_find_us_text",
        })}
      </Text>
      <Text align="center">
        {intl.formatMessage({
          id: "pages.contacts.where_to_find_us_1",
        })}
        {/* <br></br>
        {intl.formatMessage({
          id: "pages.contacts.where_to_find_us_2",
        })}
        <br></br>
        {intl.formatMessage({
          id: "pages.contacts.where_to_find_us_3",
        })}
        <br></br> */}
      </Text>
      <MapWrapper>
        <SeznamMap
          src="https://frame.mapy.cz/s/dahamopuza"
          frameborder="0"
        ></SeznamMap>
        <MapOverlay
          onDoubleClick={removeOverlay}
          onTouchStart={detectDoubleTap}
        >
          <p>
            {intl.formatMessage({
              id: "pages.contacts.map",
            })}
          </p>
        </MapOverlay>
      </MapWrapper>
      <Title noDots>
        {intl.formatMessage({
          id: "pages.contacts.invoicing_title",
        })}
      </Title>
      <Text>
        {intl.formatMessage({
          id: "pages.contacts.invoicing_id",
        })}
        : 09033149<br></br>
        {intl.formatMessage({
          id: "pages.contacts.invoicing_dic",
        })}
        : CZ09033149<br></br>
        {intl.formatMessage({
          id: "pages.contacts.invoicing_file_no",
        })}
        <br></br>
      </Text>
    </Wrapper>
  )
}

export default Contact
