import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Wrapper from "./Wrapper"
import Link from "./Link"
import BreadEnd from "./BreadEnd"
import ChevronIcon from "../../shared-styled-components/ChevronIcon"

const Breadcrumbs = ({ data }) => {
  const intl = useIntl()
  const links = data.path.split("/").filter(link => link !== "")
  const collections = data.collection?.title
    ?.split("/")
    .map((title, index) => ({
      title,
      link:
        data.collection.handle === "spodni-pradlo"
          ? [data.collection.handle]
          : data.collection.handle.split("-").slice(0, index + 1),
    }))

  const getLink = link => {
    switch (link) {
      case "pro-zakazniky":
        return "Pro zákazníky"
      case "produkty":
        return intl.locale === "en" ? "Products" : "Produkty"
      default:
        return link
    }
  }
  return (
    <Wrapper>
      <Link to="/">
        {intl.formatMessage({
          id: "breadcrumb_home",
        })}
      </Link>
      <ChevronIcon size="8" left="10px" right="10px" bottom="3px" />
      {!links.includes("produkt") &&
        links.map(
          (link, i) =>
            i < links.length - 1 && (
              <span key={link}>
                <Link to={`/${link}`}>{getLink(link)}</Link>
                <ChevronIcon size="8" left="10px" right="10px" bottom="3px" />
              </span>
            )
        )}
      {links.includes("produkt") &&
        collections?.map(collection => (
          <span key={collection.title}>
            <Link to={`/${collection.link.join().replace(",", "/")}`}>
              {collection.title}
            </Link>
            <ChevronIcon size="8" left="10px" right="10px" bottom="3px" />
          </span>
        ))}
      <BreadEnd>{data.breadEnd}</BreadEnd>
    </Wrapper>
  )
}

export default Breadcrumbs
