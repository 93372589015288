import styled from "styled-components"

const MapOverlay = styled.div`
  border: none;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  
  &:hover {
    background-color: #00000080;

    p {
      color: #fff;
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    color: transparent;
    background-color: transparent;
    height: fit-content;
    transition: 0.3s ease;
    pointer-events: none;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
  p {
    font-size: 30px;
  }
  }
`

export default MapOverlay
