import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 16px;
  gap: 8px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 32px;
    gap: 32px;
    padding: 48px;
    background: rgba(128, 128, 128, 0.2);
  }
`

export default Container
