import styled from "styled-components"

const SeznamMap = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  touch-action: none;
  position: absolute;
`

export default SeznamMap
