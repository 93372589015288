import styled from "styled-components"

const MapWrapper = styled.div`
  margin-left: -20px;
  margin-right: -20px;
  overscroll-behavior-y: contain;
  margin-bottom: 36px;
  position: relative;
  height: 230px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: 52px;
    height: 465px;
  }
`

export default MapWrapper
