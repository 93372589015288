import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import Contact from "../components/Contact"

const ContactPage = ({ location }) => {
  const intl = useIntl()
  return (
    <Layout subtitle={intl.formatMessage({ id: "pages.contacts.title" })}>
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: intl.formatMessage({ id: "pages.contacts.title" }),
        }}
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage
