import styled from "styled-components"

const Link = styled.a`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export default Link
