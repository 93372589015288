import styled from "styled-components"
import { ChevronDoubleRight } from "@styled-icons/bootstrap/ChevronDoubleRight"

const ChevronIcon = styled(ChevronDoubleRight)`
  margin-top: ${props => props.top};
  margin-bottom: ${props => props.bottom};
  margin-left: ${props => props.left};
  margin-right: ${props => props.right};
`

export default ChevronIcon
