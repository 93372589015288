import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby-plugin-react-intl"

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.text.darkGrey};
  text-transform: uppercase;

  &:hover {
    color: ${props => props.theme.colors.text.white};
  }
`

export default Link
