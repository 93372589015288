import styled, { css } from "styled-components"

const noDots = css`
  &:before {
    content: "";
  }

  &:after {
    content: "";
  }
`

const Title = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "16px")};

  &:before {
    content: "• ";
  }

  &:after {
    content: " •";
  }

  ${props => props.noDots && noDots};

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : "42px"};

    font-size: 36px;
  }
`

export default Title
