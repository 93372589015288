import styled from "styled-components"

const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "Opx")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "32px")};
  text-align: ${props => (props.align ? props.align : "left")};
  padding-top: 16px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 22px;
    padding-top: 0;
    margin-bottom: 32px;
    padding-left: 0;
    padding-right: 0;
  }
`

export default Text
